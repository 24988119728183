<template>
  <div class="ma-0 pa-0">
    <v-card class="mt-12" v-if="!initalFetch">
      <v-row d-flex flex-row :class="{'px-4': $vuetify.breakpoint.mobile}">
        <h5 class="pt-4">{{ title }}</h5>
        <v-spacer></v-spacer>
        <ExpandingBrowser v-if="!$vuetify.breakpoint.mobile" v-model="browser" @input="resetData" class="mt-1 mr-6"/>
        <ExpandingSearch v-model="filter" @input="resetData" class="mt-1 mr-6"/>
        <ListSorter v-if="!$vuetify.breakpoint.mobile" v-model="sorting" @input="resetData" class="mt-1" :items="sortItems" />
      </v-row>
      <v-row :class="{'px-2': $vuetify.breakpoint.mobile}">
        <v-chip class="ma-2" :outlined="context!=='liked'" color="primary" @click="context='liked'">
          {{ $t('library.liked') }}
        </v-chip>
        <v-chip class="ma-2" :outlined="context!=='all'" color="primary" @click="context='all'">
          {{ $t('library.all') }}
        </v-chip>
      </v-row>
      <v-row dense>
        <v-col :class="{'px-0': $vuetify.breakpoint.mobile}" v-for="(row,i) in storeData.rows" :key="i" :cols="$vuetify.breakpoint.mobile ? 12 : 'auto'">
          <slot v-bind:row="row"></slot>
        </v-col>
        <EndlessScroll @fetchMore="fetchData" :gotAll="gotAll"/>
      </v-row> 
    </v-card>
    <DetailLoader v-else />
  </div>
</template>

<script>
import EndlessScroll from '@/components/EndlessScroll'
import ExpandingSearch from '@/components/ExpandingSearch'
import ExpandingBrowser from '@/components/ExpandingBrowser'
import ListSorter from '@/components/ListSorter'
import DetailLoader from '@/components/DetailLoader'
import Lget from 'lodash/get'

export default {
  props: {
    title: {
      type: String,
    },
    sortItems: {
      type: Array,
      default: () => []
    },
    defaultSort: {
      type: String,
    },
    storeConfig: {
      type: Object
    }
  },

  data: () => ({
    initalFetch: true,
    browser: '',
    filter: '',
    sorting: '',
    isLoading: false,
    page: 0,
    context: 'liked'
  }),

  components: {
    ExpandingSearch,
    ExpandingBrowser,
    EndlessScroll,
    ListSorter,
    DetailLoader
  },

  watch: {
    context() {
      this.resetData()
    }
  },

  created () {
    this.sorting = Lget(this, 'storeData.sorting', this.defaultSort)
    this.context = Lget(this, 'storeData.context', 'liked')
    this.browser = Lget(this, 'storeData.browser', '')
    this.filter = Lget(this, 'storeData.filter', '')
    this.fetchData()
  },

  computed: {
    storeData() {
      return this.$store.getters[this.storeConfig.getter]
    },

    gotAll() {
      return this.storeData.count !== 0
        && this.storeData.rows.length === this.storeData.count
    }
  },

  methods: {
    resetData() {
      this.$store.dispatch(this.storeConfig.clearAction)
      this.page=0
      this.initalFetch=true
      this.fetchData()
    },

    fetchData() {
      if (this.isLoading || this.gotAll) {
        if (this.gotAll) {
          this.initalFetch=false
        }
        return
      }
      this.isLoading = true
      this.$store.dispatch(this.storeConfig.fetchAction, {
        browser: this.browser,
        context: this.context,
        page: this.page,
        filter: this.filter,
        sorting: this.sorting,
        ...this.storeConfig.defaultCriteria
        })
        .then((res) => {
          this.browser=res.browser
          this.context=res.context
          this.sorting=res.sorting
          this.filter=res.filter
          this.page += 1
          this.isLoading = false
          this.initalFetch = false
        })
        .catch((err) => {
          this.isLoading = false
          this.initalFetch = false
        })
    },
  }
}
</script>

<style scoped>

</style>