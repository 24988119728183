<template>
  <v-sheet rounded class="pl-4 pr-4 my-2 py-3 expanding-browser" :class="{ closed: !show }">
    <v-row d-flex flex-row>
      <div class="my-2 mr-2 pl-2" v-show="show">{{ $t('components.expanding-browser.starts-with') }}</div>
      <v-spacer></v-spacer>
      <v-btn-toggle class="mt-1" v-model="filter" @change="emitValue">
        <v-btn class="letter-button" v-show="show" small v-for="(letter,index) of letters" :key="index">{{ letter }}</v-btn>
      </v-btn-toggle>
      <v-btn ref="button" icon @click="toggle" :color="filter > -1 ? 'primary' : ''">
        <v-icon>{{ mdiAlphabetLatin }}</v-icon>
      </v-btn>
    </v-row>
  </v-sheet>
</template>

<script>
import { mdiAlphabetLatin } from '@mdi/js'

export default {
  props: {
    'value': String
  },

  watch: {
    value: {
      handler: function (newV, oldV) {
        this.filter = this.letters.indexOf(newV)
      },
      immediate: true
    }
  },

  data: () => ({
    show: false,
    filter: null,
    mdiAlphabetLatin,
  }),

  computed: {
    letters() {
      const alpha = Array.from(Array(26)).map((e, i) => i + 65)
      return ['0-9', ...(alpha.map((x) => String.fromCharCode(x))), 'Å-Ö']
    }
  },

  methods: {
    emitValue() {
      this.$emit('input',this.filter > -1 ? this.letters[this.filter] : '')
    },
    toggle(e) {
      this.show=!this.show
    }
  }
}
</script>

<style scoped>
  .letter-button {
    max-width: 12px !important;
    min-width: 12px !important;
  }
</style>

<style lang="sass">
  .v-btn-toggle
    background: transparent !important
    background-color: transparent !important
  .expanding-browser
    transition: max-width 0.3s
    background: rgba(255, 255, 255, 0.08) !important
    background-color: rgba(255, 255, 255, 0.08) !important
    max-height: 40px
    &.closed
      background: transparent !important
      background-color: transparent !important
      max-width: 45px
      overflow: hidden
</style>
