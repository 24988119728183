<template>
  <span></span>
</template>

<script>
export default {

  data: () => ({
    scrollNoMore: false
  }),

  props: {
    gotAll: {
      type: Boolean,
      default: false
    },
    minThreshold: {
      type: Number,
      default: 350
    },
    loadAt: {
      type: Number,
      default: .3
    }
  },

  created () {
    window.addEventListener('scroll', this.scroll)
    this.scrollNoMore = this.gotAll
  },

  watch: {
    gotAll: {
      handler: function () {
        this.scrollNoMore = this.gotAll
      }
    }
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.scroll);
  },

  methods: {
    scroll () {
      window.onscroll = async () => {
        let threshold = (window.innerHeight * this.loadAt) < this.minThreshold ? this.minThreshold : (window.innerHeight * this.loadAt)
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - threshold

        if (bottomOfWindow && !this.scrollNoMore) {
          // Fire fetchmore when bottom of window is reached
          // @arg The argument is a string
          this.$emit('fetchMore')
        }
      };
    }
  }
}
</script>

<style>
</style>

