<template>
  <v-card fluid class="fill-height fluid d-flex align-center justify-center">
    <div class="loader">Loading...</div>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    attrs: {
      class: 'mb-6',
      boilerplate: true,
      elevation: 2,
    },
  }),
}
</script>

<style lang="scss" scoped>
  .fill-height {
    height: 100vh;
  }

  .loader,
  .loader:after {
    border-radius: 50% !important;
    width: 5em;
    height: 5em;
  }
  .loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.6em solid rgba(255, 255, 255, 0.2);
    border-right: 0.6em solid rgba(255, 255, 255, 0.2);
    border-bottom: 0.6em solid rgba(255, 255, 255, 0.2);
    border-left: 0.6em solid var(--v-primary-base);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: rotate 1.1s infinite linear;
    animation: rotate 1.1s infinite linear;
  }
  @-webkit-keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>